import React, { Component, createContext } from 'react';
import { auth } from '../provider/firebase';

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
    state = {
        user: {
            email: 'Keine Email',
            displayName: null,
            status: 'Gründer'
        }
    };

    componentDidMount = () => {
        auth().onAuthStateChanged(user => {
            this.setState({ user: {...this.state.user, ...user} });
        })

    };
    render() {
        return (
            <UserContext.Provider value={this.state.user}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}
export default UserProvider;