const firebaseConfig = {
  apiKey: "AIzaSyD9kKFnAQQwg01RI1chz-QFIr2q7GeiCLw",
  authDomain: "circlico-dev.firebaseapp.com",
  databaseURL: "https://circlico-dev.firebaseio.com",
  projectId: "circlico-dev",
  storageBucket: "circlico-dev.appspot.com",
  messagingSenderId: "933852123766",
  appId: "1:933852123766:web:3fa1575cce33499909c557",
};

export const provider = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth;